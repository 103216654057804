<template>
  <div class="mint">
    <div v-if="loading">
      <img src="../assets/icons/spinner.svg" alt="">
    </div>
    <div class="sync" v-if="!account && !loading">
      <h1 class="title is-3">Connect your wallet.</h1>
      <p>You'll need to sync and authenticate yourself by signing a message.</p>
      <b-button v-if="!synced" @click="sync">SYNC</b-button>
    </div>
    <div v-if="(account && artist.name && !loading)">
      <h1 class="title is-2 page_title" style="text-align: left;">
        Edit artist
      </h1>
      <b-field label="Name">
        <b-input type="text" :value="artist.name" readonly></b-input>
      </b-field>

      <b-field label="Address">
        <b-input type="text" :value="artist.artistId" readonly>
        </b-input>
      </b-field>

      <b-field label="Avatar">
        <div class="is-flex is-flex-direction-column">
          <div class="gallery_images">
            <div class="gallery_img">
              <img v-if="(artist.avatar && !avatar.name)" :src="artist.avatar" alt="avatar">
              <img v-if="avatar.name" :src="returnUrl(avatar)" alt="avatar">
              <img src="../assets/images/trash.svg" class="remove_btn" alt="Remove"
                @click="(artist.avatar && !avatar.name) ? artist.avatar = null : avatar = {}">
            </div>
          </div>
          <br>
          <b-upload class="ml-0 mr-auto" accept="image/png, image/jpeg, image/jpg" v-model="avatar" drag-drop
            style="width: 400px;">
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large">
                  </b-icon>
                </p>
                <p>Drop your file here or click to upload</p>
              </div>
            </section>
          </b-upload>
        </div>
      </b-field>

      <b-field label="Description">
        <b-input maxlength="10000" class="mt-4" type="textarea" v-model="artist.description"
          style="border:1px solid #999!important"></b-input>
      </b-field>

      <b-field label="Cross" :type="(is_cross !== 'YES' && is_cross !== 'NO') ? 'is-danger' : ''"
        :message="(is_cross !== 'YES' && is_cross !== 'NO') ? 'Invalid cross status' : ''">
        <div class="is-flex">
          <b-select placeholder="Select a status" class="ml-0 mr-auto mt-3" v-model="is_cross">
            <option>
              YES
            </option>
            <option>
              NO
            </option>
          </b-select>
        </div>
      </b-field>

      <b-field label="Active status" :type="(status !== 'YES' && status !== 'NO') ? 'is-danger' : ''"
        :message="(status !== 'YES' && status !== 'NO') ? 'Invalid status' : ''">
        <div class="is-flex">
          <b-select placeholder="Select a status" class="ml-0 mr-auto mt-3" v-model="status">
            <option>
              YES
            </option>
            <option>
              NO
            </option>
          </b-select>
        </div>
      </b-field>

      <b-field label="Hidden status" :type="(hidden !== 'YES' && hidden !== 'NO') ? 'is-danger' : ''"
        :message="(hidden !== 'YES' && hidden !== 'NO') ? 'Invalid status' : ''">
        <div class="is-flex">
          <b-select placeholder="Select a status" class="ml-0 mr-auto mt-3" v-model="hidden">
            <option>
              YES
            </option>
            <option>
              NO
            </option>
          </b-select>
        </div>
      </b-field>

      <b-field label="Web 2.0 social links"
        :type="(artist.web2) ? (artist.web2.find(link => link.type == '' || link.link.indexOf('http') === -1) !== undefined) ? 'is-danger' : '' : ''"
        :message="(artist.web2) ? (artist.web2.find(link => link.type == '' || link.link.indexOf('http') === -1) !== undefined) ? 'Some links contain an error' : '' : ''">
        <div class="link_editor">
          <div class="link_group" v-for="(link, index) in artist.web2" :key="index">
            <input type="text" readonly class="link_input" :value="link.type">
            <input type="text" readonly class="link_input" :value="link.link">
            <img class="removeLink_btn" src="../assets/images/trash.svg" @click="removeLink('web2', index)">
          </div>
          <div class="link_group">
            <input type="text" class="link_input" v-model="link_editor.web2.type" placeholder="type (e.g. twitter)">
            <input type="text" class="link_input" v-model="link_editor.web2.link" placeholder="link (e.g. http://)">
            <img class="addLink_btn" src="../assets/images/plus2.png" @click="addLink('web2')">
          </div>
        </div>
      </b-field>

      <b-field label="Web 3.0 social links"
        :type="(artist.web3) ? (artist.web3.find(link => link.type == '' || link.link.indexOf('http') === -1) !== undefined) ? 'is-danger' : '' : ''"
        :message="(artist.web3) ? (artist.web3.find(link => link.type == '' || link.link.indexOf('http') === -1) !== undefined) ? 'Some links contain an error' : '' : ''">
        <div class="link_editor">
          <div class="link_group" v-for="(link, index) in artist.web3" :key="index">
            <input type="text" readonly class="link_input" :value="link.type">
            <input type="text" readonly class="link_input" :value="link.link">
            <img class="removeLink_btn" src="../assets/images/trash.svg" @click="removeLink('web3', index)">
          </div>
          <div class="link_group">
            <input type="text" class="link_input" v-model="link_editor.web3.type" placeholder="type (e.g. twitter)">
            <input type="text" class="link_input" v-model="link_editor.web3.link" placeholder="link (e.g. http://)">
            <img class="addLink_btn" src="../assets/images/plus2.png" @click="addLink('web3')">
          </div>
        </div>
      </b-field>
      <b-field :label="'Gallery (max ' + maxGalleryFiles + ' files)'">
        <table class="gallery_table" ref="gallery_table">
          <tr>
            <th>order</th>
            <th>preview</th>
            <th>link</th>
            <th>upload</th>
            <th style="width:90px">video</th>
            <th style="width: 90px;">remove</th>
          </tr>
          <tr v-for="i in maxGalleryFiles" :key="i">
            <td v-html="i"></td>
            <td v-if="galleryFiles[i - 1]" class="preview_column">
              <img
                v-if="(galleryFiles[i - 1].img && !galleryUploadedFiles[i - 1] && galleryFiles[i - 1].isVideo === false)"
                class="preview" :src="galleryFiles[i - 1].img">
              <video controls
                v-if="(galleryFiles[i - 1].img && !galleryUploadedFiles[i - 1] && galleryFiles[i - 1].isVideo === true)"
                :src="galleryFiles[i - 1].img"></video>
              <img v-if="(galleryUploadedFiles[i - 1] && galleryFiles[i - 1].isVideo === false)" class="preview"
                :src="returnUrl(galleryUploadedFiles[i - 1])">
              <video controls v-if="(galleryUploadedFiles[i - 1] && galleryFiles[i - 1].isVideo === true)"
                :src="returnUrl(galleryUploadedFiles[i - 1])"></video>
            </td>
            <td v-if="galleryFiles[i - 1]">
              <input type="text" class="link_input" style="width:300px;margin: 0 .5rem;height: 20px;"
                placeholder="http://" v-model="galleryFiles[i - 1].link">
            </td>
            <td>
              <b-upload class="ml-0 mr-auto" accept="audio/*, video/*, image/*" v-model="galleryUploadedFiles[i - 1]"
                drag-drop style="width: 200px; opacity: .8;">
                <section class="section" style="padding: .8rem .8rem!important;">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-small">
                      </b-icon>
                    </p>
                    <p style="font-size: 12px;">Drop your file here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </td>
            <td>
              <b-checkbox v-if="galleryFiles[i - 1]" :value="galleryFiles[i - 1].isVideo" class="ml-3 mr-0"
                @input="updateIsVideo(i - 1)"></b-checkbox>
            </td>
            <td>
              <div>
                <img src="../assets/images/trash.svg" alt="Remove" class="remove_gallery_btn"
                  @click="removeGalleryFile(i - 1)">
              </div>
            </td>
          </tr>
        </table>
      </b-field>

      <pre class="mt-6" v-if="signature"
        style="background: #111;border-radius: 5px;padding: 10px;text-align: left;border: 1px solid #fff;">{{ signature }}</pre>
      <b-button v-if="!isWorking" @click="(formValid) ? sign() : ''"
        :style="!formValid ? 'background:#a1a1a1;cursor:default;position:relative' : ''">SIGN<span v-if="!formValid"
          class="icon is-right has-text-danger" style="position:absolute;top:60px;left:50%;z-index: 9;"><i
            class="mdi mdi-alert-circle mdi-24px mr-2"></i>Some fields contain errors</span></b-button>
      <div v-if="isWorking" style="margin-top:20px">{{ workingMessage }}</div>
    </div>
    <div v-if="account && !artist.name && !loading">
      <h1 class="title is-size-4 has-text-weight-light page_title" style="text-align: left;">
        Artist not found for <strong class="has-text-weight-semibold">{{ $route.params.address }}</strong>
      </h1>
    </div>
  </div>
</template>

<script>
import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { SigningType, NetworkType } from "@airgap/beacon-sdk";
import axios from "axios";
const Tezos = new TezosToolkit(process.env.VUE_APP_TOOKLIT_ENDPOINT);
const beacon = new BeaconWallet({ name: "Fakewhale" });
Tezos.setWalletProvider(beacon);

export default {
  name: "Mint",
  data() {
    return {
      account: "",
      pubKey: "",
      synced: "",
      artist: {},
      keys: {},
      preview: "",
      signature: "",
      show: false,
      axios: axios,
      isPreparing: false,
      isMinting: false,
      prepared: "",
      mintingState: "Minting nft, please wait..",
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
      isWorking: false,
      workingMessage: "",
      adminAddresses: [],
      pinataUrl: process.env.VUE_APP_PINATA,
      umiUrl: process.env.VUE_APP_UMI_URL,
      galleryFiles: [],
      avatar: {},
      status: 'YES',
      hidden: 'NO',
      is_cross: 'NO',
      link_editor: {
        web2: {
          type: '',
          link: ''
        },
        web3: {
          type: '',
          link: ''
        }
      },
      galleryUploadedFiles: [],
      maxGalleryFiles: 4,
      loading: true
    };
  },
  computed: {
    formValid() {
      return ((this.is_cross === 'YES' || this.is_cross === 'NO') && (this.status === 'YES' || this.status === 'NO') && (this.hidden === 'YES' || this.hidden === 'NO') && ((this.artist.web3) ? (this.artist.web3.find(link => link.type == '' || link.link.indexOf('http') === -1) === undefined) : true) && ((this.artist.web2) ? (this.artist.web2.find(link => link.type == '' || link.link.indexOf('http') === -1) === undefined) : true))
    }
  },
  watch: {
    galleryUploadedFiles: {
      handler() {
        const app = this
        setTimeout(() => {
          app.loadAnyVideo()
        }, 300)
      }
    }
  },
  async mounted() {
    const app = this;
    await app.getAdmins()
    const cache = localStorage.getItem("tz_account");
    if (cache !== null) {
      try {
        app.keys = JSON.parse(localStorage.getItem("tz_keys"));
        app.account = localStorage.getItem("tz_account");
        app.pubKey = localStorage.getItem("tz_pubkey");
      } catch (e) {
        localStorage.removeItem("tz_account");
        localStorage.removeItem("tz_keys");
        localStorage.removeItem("tz_pubkey");
      }
      console.log("Pubkey: ", app.pubKey);
    }
    app.artist = await app.getArtist(app.$route.params.address)
    app.status = (app.artist.active === true) ? 'YES' : 'NO'
    app.hidden = (app.artist.isHidden === true) ? 'YES' : 'NO'
    app.is_cross = (app.artist.isCross === true) ? 'YES' : 'NO'
    if (!app.artist.web2) {
      app.artist.web2 = []
    }
    if (!app.artist.web3) {
      app.artist.web3 = []
    }
    let i = 0
    while (i < app.maxGalleryFiles) {
      if (app.artist.gallery && app.artist.gallery[i]) {
        app.galleryFiles.push({ link: app.artist.gallery[i].link, img: app.artist.gallery[i].img, isVideo: (app.artist.gallery[i].isVideo === undefined) ? false : app.artist.gallery[i].isVideo })
      }
      else {
        app.galleryFiles.push({ link: '', img: '', isVideo: false })
      }
      i++
    }
    app.loading = false
  },
  methods: {
    removeGalleryFile(index) {
      if (this.galleryUploadedFiles[index]) {
        this.galleryUploadedFiles[index] = undefined
      }
      if (this.galleryFiles[index].img) {
        this.galleryFiles[index].img = ""
      }
    },
    async getAdmins() {
      const res = await axios.get(this.lambdaURL + '/admins')
      this.adminAddresses = res.data.admins
    },
    loadAnyVideo() {
      try {
        const app = this
        // loading video inside video tag if exists
        Array.from(app.$refs.gallery_table.children).forEach(tr => {
          Array.from(tr.children).forEach(td => {
            if (td.children && Array.from(td.children).find(el => el.nodeName === 'VIDEO')) {
              let video = Array.from(td.children).find(el => el.nodeName === 'VIDEO')
              if (video.readyState !== 4)
                video.load()
            }
          })
        })
      }
      catch { return }
    },
    updateIsVideo(index) {
      this.galleryFiles[index].isVideo = !this.galleryFiles[index].isVideo
      setTimeout(() => { this.loadAnyVideo() }, 500)
    },
    async sync() {
      const app = this;
      try {
        console.log("Asking permissions...");
        const permissions = await beacon.client.requestPermissions({
          network: { type: NetworkType.MAINNET },
        });
        console.log("Got permissions:", permissions);
        if (app.adminAddresses.indexOf(permissions.address) !== -1) {
          app.account = permissions.address;
          app.pubKey = permissions.publicKey;
          localStorage.setItem('tz_account', app.account)
          localStorage.setItem('tz_pubkey', app.pubKey)
          localStorage.setItem('tz_keys', JSON.stringify(permissions))
        } else {
          alert("Not the admin!");
        }
      } catch (error) {
        console.log("Got error:", error);
      }
    },
    async sign() {
      const app = this;
      app.isWorking = true;
      app.workingMessage = "Please confirm signature in wallet..";
      const toSign = await app.axios.post(app.lambdaURL + "/challenge", {
        message: app.$route.params.address,
      });
      const response = await beacon.client.requestSignPayload({
        signingType: SigningType.MICHELINE,
        payload: toSign.data.toSign,
      });
      app.signature = response;
      console.log("Message", toSign.data.toSign);
      console.log("Signature", response.signature);
      app.workingMessage = "Updating artist..";
      // avatar
      let avatar = ''
      if (app.avatar.name) {
        //upload on ipfs
        const ext = app.avatar.name.split(".")[app.avatar.name.split(".").length - 1];
        const supported = ["png", "jpg", "jpeg"];
        if (supported.indexOf(ext) !== -1) {
          const formData = new FormData();
          formData.append("file", app.avatar);
          formData.append("name", app.avatar.name);
          try {
            let ipfsImageUpload = await axios({
              method: "post",
              url: app.umiUrl + "/ipfs/upload",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              }
            });
            if (
              ipfsImageUpload.data.error !== undefined &&
              ipfsImageUpload.data.error === false
            ) {
              avatar = app.pinataUrl + '/ipfs/' + ipfsImageUpload.data.ipfs_hash
            } else {
              app.isWorking = false;
              alert("ipfs upload failed. Please, retry later")
              return
            }
          }
          catch {
            app.isWorking = false;
            alert("ipfs upload failed. Please, retry later")
            return
          }
        }
        else {
          alert(`File extension ${ext} is not supported for avatar`)
          return
        }
      }
      else {
        avatar = (app.artist.avatar) ? app.artist.avatar : ''
      }
      // gallery
      app.artist.gallery = []
      let fileIndex = 0
      while (fileIndex < app.maxGalleryFiles) {
        let file = app.galleryUploadedFiles[fileIndex]
        let img = ''
        if (file !== undefined) {
          try {
            // upload file on ipfs
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", file.name);
            let ipfsImageUpload = await axios({
              method: "post",
              url: app.umiUrl + "/ipfs/upload",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              }
            });
            if (
              ipfsImageUpload.data.error !== undefined &&
              ipfsImageUpload.data.error === false
            ) {
              img = app.pinataUrl + '/ipfs/' + ipfsImageUpload.data.ipfs_hash
            } else {
              app.isWorking = false;
              alert("ipfs upload failed. Please, retry later")
              return;
            }
          }
          catch {
            app.isWorking = false;
            alert("ipfs upload failed. Please, retry later")
            return
          }
        }
        else {
          // old image (not changed)
          img = app.galleryFiles[fileIndex].img
        }
        app.artist.gallery.push({
          link: app.galleryFiles[fileIndex].link,
          img: img,
          isVideo: app.galleryFiles[fileIndex].isVideo
        })
        fileIndex++;
      }

      const add = await axios.post(app.lambdaURL + "/admin/artists/update", {
        signature: response.signature,
        message: toSign.data.toSign,
        public_key: app.pubKey,
        is_cross: (app.is_cross === 'YES') ? true : false,
        state: (app.status === 'YES') ? true : false,
        hidden: (app.hidden === 'YES') ? true : false,
        description: app.artist.description,
        avatar: avatar,
        web2: app.artist.web2,
        web3: app.artist.web3,
        gallery: app.artist.gallery
      });
      app.isWorking = false;
      alert(add.data.message);
    },
    async unsync() {
      const app = this;
      app.account = "";
      localStorage.removeItem("tz_account");
      localStorage.removeItem("tz_keys");
      localStorage.removeItem("tz_pubkey");
    },
    async getArtist(address) {
      const app = this
      try {
        const artist = await axios.get(`${app.lambdaURL}/artists/${address}`).then(res => res.data.artist)
        return (artist) ? artist : {}
      }
      catch (e) {
        console.log(e)
        return {}
      }
    },
    returnUrl(file) {
      try {
        return URL.createObjectURL(file)
      }
      catch { return null }
    },
    removeLink(what, index) {
      this.artist[what][index] = undefined
      let temp = []
      this.artist[what].forEach(el => {
        if (el)
          temp.push(el)
      })
      this.artist[what] = temp
      this.$forceUpdate()
    },
    addLink(what) {
      this.artist[what].push({
        type: this.link_editor[what].type,
        link: this.link_editor[what].link
      })
      this.$forceUpdate()
    }
  },
};
</script>

<style scoped>
.gallery_images {
  display: flex;
  flex-wrap: wrap;
}

.gallery_img {
  width: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow: hidden;
  margin: .5rem;
}

.gallery_img .remove_btn {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  filter: invert(1);
  opacity: 0;
  transition: all .13s ease-in-out;
  cursor: pointer;
}

.gallery_img::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .13s ease-in-out;
}

.gallery_img:hover:after {
  background: rgba(0, 0, 0, .5);
}

.gallery_img:hover .remove_btn {
  top: 1rem;
  right: 1rem;
  opacity: 1;
}

.link_editor {
  display: flex;
  flex-direction: column;
}

.link_editor .link_group {
  display: flex;
}

.link_group .addLink_btn {
  filter: invert(.7);
  width: 35px;
  height: 36px;
  margin-left: -6px;
  margin-top: 25px;
  transition: .13s ease-in-out;
  cursor: pointer;
}

.link_group .addLink_btn:hover {
  opacity: .6;
}

.removeLink_btn {
  filter: invert(.6);
  opacity: .2;
  margin-top: 10px;
  cursor: pointer;
  transition: .13s ease-in-out;
}

.removeLink_btn:hover {
  opacity: .9;
}

.remove_gallery_btn {
  transition: .13s ease-in-out;
  filter: invert(.5);
  cursor: pointer;
}

.remove_gallery_btn:hover {
  filter: invert(1);
}

.link_input {
  border: 1px solid grey !important;
  font-size: 1rem !important;
  background: none;
  font-family: 'DM Mono', monospace;
  color: #ccc;
  padding: 1rem;
  margin: 1rem 1rem 0 0;
  width: 300px;
}

.link_input:focus {
  outline: none;
}

.gallery_table {
  margin-top: 2rem;
}

.gallery_table tr {
  border-bottom: 1px solid rgb(41, 41, 41);
}

.gallery_table th {
  color: #ccc;
}

.gallery_table td {
  vertical-align: middle;
}

.gallery_table .preview {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

@media(max-width:767px) {
  .preview_column {
    width: 250px;
    display: block;
  }
}
</style>