<template>
  <div class="mint">
    <div v-if="loading">
      <img src="../assets/icons/spinner.svg" alt="">
    </div>
    <div class="sync" v-if="!account && !loading">
      <h1 class="title is-3">Connect your wallet.</h1>
      <p>You'll need to sync and authenticate yourself by signing a message.</p>
      <b-button v-if="!synced" @click="sync">SYNC</b-button>
    </div>
    <div v-if="account">
      <h1 class="title is-2 page_title">
        Add artist
      </h1>
      <b-field label="Address">
        <b-input placeholder="address of artist.." v-model="address"></b-input>
      </b-field>
      <b-field label="Name">
        <b-input placeholder="name of artist.." v-model="name"></b-input>
      </b-field>
      <pre v-if="signature" style="
          background: #111;
          border-radius: 5px;
          padding: 10px;
          text-align: left;
          border: 1px solid #fff;
        ">{{ signature }}</pre>
      <b-button v-if="!isWorking" @click="sign">SIGN</b-button>
      <div v-if="isWorking" style="margin-top:20px">{{ workingMessage }}</div>
    </div>
  </div>
</template>

<script>
import { TezosToolkit, MichelsonMap } from "@taquito/taquito";
import { char2Bytes } from "@taquito/utils";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { SigningType, NetworkType } from "@airgap/beacon-sdk";
import axios from "axios";
const Tezos = new TezosToolkit(process.env.VUE_APP_TOOKLIT_ENDPOINT);
const beacon = new BeaconWallet({ name: "Fakewhale" });
Tezos.setWalletProvider(beacon);

export default {
  name: "Mint",
  data() {
    return {
      account: "",
      pubKey: "",
      synced: "",
      artist: {},
      keys: {},
      fileToMint: {},
      address: "",
      preview: "",
      description: "",
      signature: "",
      show: false,
      axios: axios,
      isPreparing: false,
      isMinting: false,
      prepared: "",
      mintingState: "Minting nft, please wait..",
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
      name: "",
      isWorking: false,
      workingMessage: "",
      adminAddresses: [],
      loading: true
    };
  },
  async mounted() {
    const app = this;
    await app.getAdmins()
    const cache = localStorage.getItem("tz_account");
    if (cache !== null) {
      try {
        app.keys = JSON.parse(localStorage.getItem("tz_keys"));
        app.artist = JSON.parse(localStorage.getItem("tz_artist"));
        app.account = localStorage.getItem("tz_account");
        app.pubKey = localStorage.getItem("tz_pubkey");
      } catch (e) {
        localStorage.removeItem("tz_account");
        localStorage.removeItem("tz_keys");
        localStorage.removeItem("tz_pubkey");
        localStorage.removeItem("tz_artist");
      }
      console.log("Pubkey: ", app.pubKey);
    }
    app.loading = false
  },
  watch: {
    fileToMint() {
      try {
        this.preview = URL.createObjectURL(this.fileToMint);
      } catch (e) {
        this.preview = "";
      }
    },
  },
  methods: {
    async getAdmins() {
      const res = await axios.get(this.lambdaURL + '/admins')
      this.adminAddresses = res.data.admins
    },
    async sync() {
      const app = this;
      try {
        console.log("Asking permissions...");
        const permissions = await beacon.client.requestPermissions({
          network: { type: NetworkType.MAINNET },
        });
        console.log("Got permissions:", permissions);
        if (app.adminAddresses.indexOf(permissions.address) !== -1) {
          app.account = permissions.address;
          app.pubKey = permissions.publicKey;
          localStorage.setItem('tz_account', app.account)
          localStorage.setItem('tz_pubkey', app.pubKey)
          localStorage.setItem('tz_keys', JSON.stringify(permissions))
        } else {
          alert("Not the admin!");
        }
      } catch (error) {
        console.log("Got error:", error);
      }
    },
    async sign() {
      const app = this;
      app.isWorking = true;
      app.workingMessage = "Please confirm signature in wallet..";
      const toSign = await app.axios.post(app.lambdaURL + "/challenge", {
        message: app.address,
      });
      const response = await beacon.client.requestSignPayload({
        signingType: SigningType.MICHELINE,
        payload: toSign.data.toSign,
      });
      app.signature = response;
      console.log("Message", toSign.data.toSign);
      console.log("Signature", response.signature);
      app.workingMessage = "Adding artist..";
      const add = await axios.post(app.lambdaURL + "/admin/artists/add", {
        signature: response.signature,
        message: toSign.data.toSign,
        public_key: app.pubKey,
        artist: {
          name: app.name,
          description: "-",
        },
      });
      app.isWorking = false;
      alert(add.data.message);
      app.$router.push({ name: 'Update', params: { address: app.address } })
    },
    async unsync() {
      const app = this;
      app.account = "";
      localStorage.removeItem("tz_account");
      localStorage.removeItem("tz_keys");
      localStorage.removeItem("tz_artist");
      localStorage.removeItem("tz_pubkey");
    },
  },
};
</script>