<template>
  <div class="mint">
    <div v-if="tezLoading">
      <img src="../assets/icons/spinner.svg" alt="">
    </div>
    <div class="sync" v-if="!account && !tezLoading">
      <h1 class="title is-3">Connect your wallet.</h1>
      <p>You'll need to sync and authenticate yourself by signing a message.</p>
      <b-button v-if="!synced" @click="sync">SYNC</b-button>
    </div>
    <div v-if="account">
      <h1 class="title is-2 page_title">
        Artist list
        <a href="/#/new" class="add_artist_btn"><b-button>ADD ARTIST</b-button></a>
      </h1>
      <div class="search_group">
        <input type="text" placeholder="search" v-model="searcher">
        <img src="../assets/images/search.svg" alt="search">
      </div>
      <div class="artists" v-if="!loading">
        <div class="artist" v-for="artist in artistsFiltered" :key="artist.artistId"
          @click="$router.push({ name: 'Artist', params: { address: artist.artistId } })">
          <div class="artist_img" v-if="artist.avatar" :style="'background-image: url(' + artist.avatar + ')'">
          </div>
          <div class="artist_img artist_img_empty" v-else>
          </div>
          <div class="artist_caption">
            <i class="is-size-7">Added at: {{ new Date(artist.addedAt).toLocaleString() }}</i>
            <h2 class="is-size-5 has-text-weight-bold" v-html="artist.name"></h2>
            <p class="is-size-7 has-text-weight-light mt-3"
              v-html="(artist.description.length > 299) ? artist.description.slice(0, 299).split('\n').join('<br>') + '...' : artist.description.split('\n').join('<br>')">
            </p>
            <div class="links">
              <div class="web2">
                <a class="is-size-7" target="_blank" v-for="link in artist.web2" :key="link.link" :href="link.link"
                  v-html="link.type"></a>
              </div>
              <div class="web3">
                <a class="is-size-7" target="_blank" v-for="link in artist.web3" :key="link.link" :href="link.link"
                  v-html="link.type"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="artists" v-if="loading">
        <div class="artist" v-for="i in 4" :key="i">
          <div class="artist_img loading_box" style="opacity:.4">
          </div>
          <div class="artist_caption">
            <i class="is-size-7" style="display:flex">Added at: <span class="loading_box"
                style="width:100px;height: 13px;margin-left: 4px;margin-top: -3px;"></span></i>
            <h2 class="is-size-5 has-text-weight-bold">
              <div class="loading_box" style="width:40%;height: 20px;margin-top: .8rem;"></div>
            </h2>
            <div class="is-size-7 has-text-weight-light">
              <div class="is-flex mt-4" style="opacity: .5;">
                <div class="loading_box" style="width:70%;height: 13px;"></div>
                <div class="loading_box" style="width:10%;height: 13px;margin-left: 4px;"></div>
              </div>
              <div class="is-flex mt-2" style="opacity: .5;">
                <div class="loading_box" style="width:40%;height: 13px;"></div>
                <div class="loading_box" style="width:10%;height: 13px;margin-left: 4px;"></div>
                <div class="loading_box" style="width:30%;height: 13px;margin-left: 4px;"></div>
              </div>
              <div class="is-flex mt-2" style="opacity: .5;">
                <div class="loading_box" style="width:20%;height: 13px;"></div>
                <div class="loading_box" style="width:70%;height: 13px;margin-left: 4px;"></div>
              </div>
              <div class="is-flex mt-5">
                <div class="loading_box" style="width:10%;height: 14px;"></div>
                <div class="loading_box" style="width:10%;height: 14px;margin-left: 4px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TezosToolkit, MichelsonMap } from "@taquito/taquito";
import { char2Bytes } from "@taquito/utils";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { TextileUploader } from "textile-uploader";
import { SigningType, NetworkType } from "@airgap/beacon-sdk";
import axios from "axios";
const Tezos = new TezosToolkit(process.env.VUE_APP_TOOKLIT_ENDPOINT);
const beacon = new BeaconWallet({ name: "Fakewhale" });
Tezos.setWalletProvider(beacon);

export default {
  name: "Mint",
  data() {
    return {
      account: "",
      pubKey: "",
      synced: "",
      artist: {},
      keys: {},
      fileToMint: {},
      name: "",
      preview: "",
      description: "",
      tags: "",
      show: false,
      axios: axios,
      isPreparing: false,
      isMinting: false,
      prepared: "",
      mintingState: "Minting nft, please wait..",
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
      adminAddresses: [],
      artists: [],
      artistsFiltered: [],
      loading: true,
      searcher: '',
      tezLoading: true
    };
  },
  async mounted() {
    const app = this;
    await app.getAdmins()
    const cache = localStorage.getItem("tz_account");
    if (cache !== null) {
      try {
        app.keys = JSON.parse(localStorage.getItem("tz_keys"));
        app.artist = JSON.parse(localStorage.getItem("tz_artist"));
        app.account = localStorage.getItem("tz_account");
        app.pubKey = localStorage.getItem("tz_pubkey");
      } catch (e) {
        localStorage.removeItem("tz_account");
        localStorage.removeItem("tz_keys");
        localStorage.removeItem("tz_pubkey");
        localStorage.removeItem("tz_artist");
      }
    }
    app.tezLoading = false
    await app.getArtists()
    app.loading = false
  },
  watch: {
    fileToMint() {
      try {
        this.preview = URL.createObjectURL(this.fileToMint);
      } catch (e) {
        this.preview = "";
      }
    },
    searcher: {
      handler() {
        let searcher = this.searcher.toLowerCase()
        if (searcher) {
          this.artistsFiltered = this.artists.filter(el => {
            return el.name.toLowerCase().indexOf(searcher) !== -1 || el.artistId.toLowerCase().indexOf(searcher) !== -1 || el.description.toLowerCase().indexOf(searcher) !== -1
          })
        }
        else {
          this.artistsFiltered = this.artists
        }
      }
    }
  },
  methods: {
    async getAdmins() {
      const res = await axios.get(this.lambdaURL + '/admins')
      this.adminAddresses = res.data.admins
    },
    async sync() {
      const app = this;
      try {
        console.log("Asking permissions...");
        const permissions = await beacon.client.requestPermissions({
          network: { type: NetworkType.MAINNET },
        });
        console.log("Got permissions:", permissions);
        if (app.adminAddresses.indexOf(permissions.address) !== -1) {
          app.account = permissions.address;
          app.pubKey = permissions.publicKey;
          localStorage.setItem('tz_account', app.account)
          localStorage.setItem('tz_pubkey', app.pubKey)
          localStorage.setItem('tz_keys', JSON.stringify(permissions))
        } else {
          alert("Not the admin!");
        }
      } catch (error) {
        console.log("Got error:", error);
      }
    },
    readFile(file) {
      return new Promise((response) => {
        var reader = new FileReader();
        reader.onload = function (event) {
          var readed = event.target.result;
          response(readed);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async prepare() {
      const app = this;
      if (!app.isPreparing) {
        app.isPreparing = true;
        if (
          app.name.length > 0 &&
          app.description.length > 0 &&
          app.fileToMint.name.length > 0
        ) {
          const textile = new TextileUploader(
            app.keys.pub,
            app.keys.prv,
            app.keys.buck
          );
          const content = await app.readFile(app.fileToMint);
          const ext =
            app.fileToMint.name.split(".")[
            app.fileToMint.name.split(".").length - 1
            ];
          console.log("Extension file is:", ext);
          const supported = ["mp4", "gif", "png", "jpg"];
          if (supported.indexOf(ext) !== -1) {
            const media = await textile.upload(
              content,
              new Date().getTime().toString() + "." + ext
            );
            if (media !== undefined && media.ipfs !== "Error") {
              const toSign = await app.axios.post(
                app.lambdaURL + "/challenge",
                {
                  message: "Create nft metadata for my nft.",
                }
              );
              const response = await beacon.client.requestSignPayload({
                signingType: SigningType.MICHELINE,
                payload: toSign.data.toSign,
              });
              const nft = await app.axios.post(app.lambdaURL + "/nfts/create", {
                message: toSign.data.toSign,
                public_key: app.pubKey,
                signature: response.signature,
                media_ipfs: media.ipfs.replace("/ipfs/", ""),
                title: app.name,
                description: app.description,
                tags: app.tags.replace(/\s/g, "").split(","),
              });
              if (
                nft.data.metadata !== undefined &&
                nft.data.metadata.ipfs !== undefined
              ) {
                app.prepared = nft.data.metadata.ipfs;
                app.isPreparing = false;
              } else {
                app.$buefy.snackbar.open(
                  `Error while creating metadata, retry.`
                );
                app.isPreparing = false;
              }
            } else {
              app.isPreparing = false;
              app.$buefy.snackbar.open(
                `IPFS media failed, file seems invalid.`
              );
            }
          } else {
            app.$buefy.snackbar.open(`File not supported, retry.`);
          }
        } else {
          app.$buefy.snackbar.open(`Please fill all required fields.`);
        }
      }
    },
    async mint() {
      const app = this;
      if (!app.isMinting) {
        app.isMinting = true;
        const activeAccount = await beacon.client.getActiveAccount();
        if (activeAccount) {
          try {
            console.log(
              "Creating contract instance at " +
              process.env.VUE_APP_CONTRACT_ADDRESS
            );
            const contract = await Tezos.wallet.at(
              process.env.VUE_APP_CONTRACT_ADDRESS
            );
            console.log("Starting minting request..");
            console.log("Account is:", app.account);
            const minted = await app.axios.get(
              process.env.VUE_APP_TZKT_ENPOINT +
              "/tokens/transfers/count?token.contract=" +
              process.env.VUE_APP_CONTRACT_ADDRESS +
              "&to=" +
              app.account
            );
            console.log("Account minted:", minted.data);
            let nextId =
              app.artist.tokenBase.toString() +
              (parseInt(minted.data) + 1).toString();
            console.log("Next token id is:", nextId);
            const tokens = [
              {
                token_id: nextId,
                token_info: new MichelsonMap(),
              },
            ];
            tokens[0].token_info.set("", char2Bytes("ipfs://" + app.prepared));
            const minting = await contract.methods
              .mint([{ owner: app.account, tokens: tokens }])
              .send();
            await minting.confirmation();
            app.$buefy.snackbar.open(
              `Nft minted correctly, now transfer to collection.`
            );
            app.mintingState = "Transferring, please wait..";
            const transfers = [
              {
                to_: process.env.VUE_APP_RECEIVER_ADDRESS,
                token_id: nextId,
                amount: 1,
              },
            ];
            const transfer = await contract.methods
              .transfer([{ from_: app.account, txs: transfers }])
              .send();
            await transfer.confirmation();
            app.$buefy.snackbar.open(`Minting complete!`);
            app.isMinting = false;
            app.name = "";
            app.description = "";
            app.tags = "";
            app.fileToMint = {};
            app.mintingState = "Minting nft, please wait..";
            app.prepared = "";
            app.axios.get(app.lambdaURL + "/daemon/scan/" + app.account);
          } catch (error) {
            console.log(error);
            app.isMinting = false;
          }
        } else {
          app.$buefy.snackbar.open(`Can't get active account, unsyncing.`);
          app.unsync();
        }
      }
    },
    async unsync() {
      const app = this;
      app.account = "";
      localStorage.removeItem("tz_account");
      localStorage.removeItem("tz_keys");
      localStorage.removeItem("tz_artist");
      localStorage.removeItem("tz_pubkey");
    },
    async getArtists() {
      const app = this
      try {
        const res = await axios.get(`${app.lambdaURL}/artists`)
        this.artists = res.data.artists
        this.artists.sort((a, b) => {
          return b.tokenBase - a.tokenBase
        })
        this.artistsFiltered = this.artists
      }
      catch (e) {
        console.log(e)
      }
    }
  },
};
</script>

<style scoped>
.page_title {
  text-align: left;
  margin-top: 10px;
  position: relative
}

.add_artist_btn {
  position: absolute;
  top: -30px;
  right: 0
}

.artist {
  background: rgb(151 151 151 / 20%);
  box-shadow: 1px 2px 24px rgba(0, 0, 0, .15);
  border-radius: .3rem;
  display: flex;
  margin: 3rem auto 0 auto;
  transition: .13s ease-in-out;
  cursor: pointer;
}

.artist:hover {
  transform: scale(1.02);
}

.artist_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  flex-basis: 20%;
}

.artist_img_empty {
  position: relative;
}

.artist_img_empty::after {
  content: 'no avatar';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center center;
  color: white;
  z-index: 99;
  opacity: .7;
}

.artist_caption {
  flex-basis: 80%;
  box-sizing: border-box;
  text-align: left;
  padding: 1rem;
}

.artist_caption i {
  color: rgb(107, 107, 107);
}

.search_group {
  background: rgb(151 151 151 / 20%);
  display: flex;
  width: 250px;
  padding: .6rem;
  border-radius: 999px;
  margin: 3rem auto 0 0;
}

.search_group img {
  width: 10%;
  filter: invert(1);
}

.search_group input {
  width: 90%;
  background: none;
  border: none;
  color: white;
}

.search_group input::placeholder {
  color: rgb(173, 173, 173) !important;
}

.search_group input:focus {
  border: none;
  outline: none;
}

.links {
  display: flex;
}

.links a {
  margin: 0 .6rem 0 0;
}

.web2 {
  margin: 0 auto 0 0;
}

.web3 {
  margin: 0 0 0 auto;
}

@media(max-width:767px) {
  .search_group {
    margin: 0 auto 0 auto;
    width: 100%;
  }

  .page_title {
    display: flex;
    flex-direction: column;
    text-align: center
  }

  .add_artist_btn {
    position: relative;
    margin: 2rem auto 0 auto;
  }

  .artist {
    flex-direction: column;
  }

  .artist_img {
    flex-basis: 225px;
  }

  .artist_img_empty {
    flex-basis: 85px;
  }

  .artist_caption {
    flex-basis: auto;
    height: fit-content;
  }
}
</style>
