import Vue from 'vue'
import VueRouter from 'vue-router'
import Artists from '../views/Artists.vue'
import Artist from '../views/Artist.vue'
import New from '../views/New.vue'
import Update from '../views/Update.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Artists',
        component: Artists
    },
    {
        path: '/new',
        name: 'New',
        component: New
    },
    {
        path: '/watch/:address',
        name: 'Artist',
        component: Artist
    },
    {
        path: '/update/:address',
        name: 'Update',
        component: Update
    }
]

const router = new VueRouter({
    routes
})

export default router